import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SectionRight from "../components/sectionRight"
import SectionLeft from "../components/sectionLeft"


import Hero1 from "../images/epic_jumps.jpg"
import Hero2 from "../images/sick_barrels.jpg"
import Hero3 from "../images/rad_dude.jpg"
import VideoButton from "../components/videoButton"
import DemoFooter from "../components/demoFooter"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartPie, faBroom, faDatabase, faClock } from "@fortawesome/free-solid-svg-icons"

export default function() {
  return (
    <Layout>
      <SEO title="INNOVATIVE SOLUTIONS, FASTER SUCCESS"/>

      <Hero image={Hero1} backgroundPosition={"100% center"} hideMobile={false}>
        <div className={"col col-lg-6 pr-lg-5 align-self-center text-white py-5"}>
          <h1
            style={{
              fontFamily: "Anton",
              fontWeight: 400,
              fontSize: "5rem",
            }}
          >
            INNOVATIVE SOLUTIONS.<br/>
            FASTER SUCCESS.
          </h1>
          <p className={"lead d-none d-lg-block"}>
            Powersports Support offers a one of a kind software solution platform that is designed to assist dealers with every area of their business. PSS specializes in helping industry dealers and
            distributors increase their revenue and streamline their inventory management.
          </p>
        </div>
      </Hero>

      <SectionRight
        image={Hero2}
        alt={"Man riding motorcycle."}
        title={"Our Platform"}
      >
        <p>Built on the first-hand experience we've gained in the POWERSPORTS industry</p>
        <VideoButton videoId={"nJFcKOhDOL0"} label={"Watch eBay Video"} secondary={false}/>
      </SectionRight>

      <SectionLeft image={Hero3} alt={"Man driving Utility Vehicle"} title={"Why Powersports Support?"}>
        <p className={"font-weight-bold"}>No more wasted data entry time</p>
        <p className={"font-weight-bold"}>No contracts</p>
        <p className={"font-weight-bold"}>Flat monthly fee</p>
        <Link to={"/pricing"} className={"btn btn-lg btn-secondary w-75"}>PRICING</Link>
      </SectionLeft>

      <div className={"row d-none d-md-block"} style={{ backgroundColor: "#f2f2f2" }}>
        <div className={"col-12 text-center p-5 text-primary"}>
          <h1
            style={{
              fontFamily: "Anton",
              fontSize: "4rem",
            }}
          >
            WE HAVE THE TOOLS TO HELP YOUR BUSINESS GROW FASTER
          </h1>
        </div>
        <div className={"col-12"}>
          <div className={"row justify-content-center p-5 text-center"}>
            <div className={"col-md-4 mb-5"}>
              <div className={"mb-3"}>
                <FontAwesomeIcon icon={faChartPie} size={"6x"}/>
              </div>
              <p className={"lead mb-5"}>All Major Marketplaces</p>
              <Link to={"/integrated-marketplaces/"} className={"btn btn-lg btn-secondary w-75"}>LEARN MORE</Link>
            </div>
            <div className={"col-md-4 mb-5"}>
              <div className={"mb-3"}>
                <FontAwesomeIcon icon={faBroom} size={"6x"}/>
              </div>
              <p className={"lead mb-5"}>Clean Product Data</p>
              <Link to={"/distribution-partners/"} className={"btn btn-lg btn-secondary w-75"}>LEARN MORE</Link>
            </div>
            <div className={"col-md-4 mb-5"}>
              <div className={"mb-3"}>
                <FontAwesomeIcon icon={faDatabase} size={"6x"}/>
              </div>
              <p className={"lead mb-5"}>Painless Integrations</p>
              <Link to={"/ecommerce-tools"} className={"btn btn-lg btn-secondary w-75"}>LEARN MORE</Link>
            </div>
          </div>
        </div>
      </div>
      <DemoFooter/>
    </Layout>
  )
}
